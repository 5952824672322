@font-face {
  font-family: PlusJakartaText;
  src: url(./assets/fonts/PlusJakartaText-Regular.otf);
}
@font-face {
  font-family: PlusJakartaText;
  src: url(./assets/fonts/PlusJakartaText-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: PlusJakartaText;
  src: url(./assets/fonts/PlusJakartaText-Italic.otf);
  font-style: italic;
}
@font-face {
  font-family: PlusJakartaText;
  src: url(./assets/fonts/PlusJakartaText-BoldItalic.otf);
  font-weight: bold;
  font-style: italic;
}

.spinner {
  animation: spin infinite 10 linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}

/* Override MUI data grid for tooltip */
.MuiList-root .MuiButtonBase-root {
  display: flex;
  padding: 6px 16px;
  justify-content: flex-start;
}

.MuiDataGrid-panelWrapper .MuiButtonBase-root {
  position: absolute;
}

/* Override MUI Solona wallet popup */
.MuiDialogTitle-root .MuiTypography-root {
  color: white;
}
.MuiDialogContent-root .MuiListItem-root .MuiButton-root {
  color: #8c99af;
}

/* customize scrollbar css */
*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
*::-webkit-scrollbar:horizontal {
  height: 12px;
}
*::-webkit-scrollbar-track {
  /* border: 1px #000000 solid; */
  border-radius: 10px;
  /* -webkit-box-shadow: 0 0 6px #c8c8c8 inset; */
}
*::-webkit-scrollbar-thumb {
  background-color: #ffffff40;
  border: 3px solid #000000;
  border-radius: 16px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff80;
  border: 3px solid #000000;
}
*::-webkit-scrollbar-thumb:active {
  background-color: #ffffff80;
  border: 3px solid #000000;
}

.dark_paper {
  background-color: #222222;
  margin: 20;
  margin-top: 0;
  border: 1px solid #374151;
  border-radius: 8;
  color: white;
}
